import { TableBody as TableBodyMUI } from '@material-ui/core';
import { BookingWrapper } from '@spike/booking-model';
import { AvailableStaff } from '@spike/available-staff-model';
import { FunctionComponent } from 'react';
import { BookingsView } from '@spike/bookings-view';
import AppointmentRow from './AppointmentRow';
import BookingRow from './BookingRow';

interface Props {
    wrappers: Array<BookingWrapper>;
    view: BookingsView;
    viewClient?: boolean;
    onShowAppointment: (appointmentId: number) => void;
    onShowBooking: (bookingId: number, appointmentId: number) => void;
    onConfirm: (bookingId: number) => void;
    onAssignStaff: (
        appointmentId: number,
        member: AvailableStaff | undefined
    ) => void;
    onCheckIn: (appointmentId: number) => void;
    onStart: (appointmentId: number) => void;
    onComplete: (appointmentId: number, businessAreaId: string) => void;
    onCheckout: (appointmentId: number) => void;
    onShowReport: (
        appointmentId: number,
        businessAreaId: string,
        clientId?: number
    ) => void;
    onCancel: (appointmentId: number) => void;
    onNoShow: (appointmentId: number) => void;
    onShowInvoice: (appointmentId: number) => void;
    onShowPet: (petId: number) => void;
    onShowClient: (clientId: number) => void;
    onReschedule: (appointmentId: number) => void;
    onRescheduleBooking: (bookingId: number) => void;
    onCancelBooking: (bookingId: number) => void;
    onBook: (clientId: number, petId: number, name: string) => void;
    onDecline: (appointmentId: number, businessAreaId: string) => void;
    onUndoAppointment: (appointmentId: number, statusId: number) => void;
    onShowAddReport: (
        appointmentId: number,
        businessAreaId: string,
        clientId: number
    ) => void;
    onShowAddReportBooking?: (
        bookingId: number,
        appointmentId: number,
        businessAreaId: string,
        clientId: number
    ) => void;
}

export const TableBody: FunctionComponent<Props> = props => {
    return (
        <TableBodyMUI>
            {props.wrappers.map(w =>
                w.booking ? (
                    <BookingRow
                        key={w.key}
                        booking={w.booking}
                        viewClient={props.viewClient}
                        onShow={(bookingId: number, appointmentId: number) =>
                            props.onShowBooking(bookingId, appointmentId)
                        }
                        onCancel={props.onCancelBooking}
                        onConfirm={props.onConfirm}
                        onAssignStaff={props.onAssignStaff}
                        onShowPet={props.onShowPet}
                        onShowClient={props.onShowClient}
                        onReschedule={props.onRescheduleBooking}
                        onNoShow={() =>
                            props.onNoShow(w.booking!.appointments[0]!.id!)
                        }
                        onShowReport={() =>
                            props.onShowReport(
                                w.booking!.appointments[0]!.id!,
                                w.booking!.appointments[0]!.services[0]
                                    .businessArea.id,
                                w.booking?.appointments[0].customer.id
                            )
                        }
                        onShowAddReport={() =>
                            props.onShowAddReportBooking &&
                            props.onShowAddReportBooking(
                                w.booking!.id!,
                                w.booking!.appointments[0]!.id!,
                                w.booking!.appointments[0]!.services[0]
                                    .businessArea.id,
                                w.booking!.appointments[0].customer.id
                            )
                        }
                    />
                ) : (
                    <AppointmentRow
                        key={w.key}
                        appointment={w.appointment!}
                        view={props.view}
                        viewClient={props.viewClient}
                        onShow={() => {
                            props.onShowAppointment(w.appointment!.id!);
                        }}
                        onAssignStaff={staff =>
                            props.onAssignStaff(w.appointment!.id!, staff)
                        }
                        onCheckIn={() => props.onCheckIn(w.appointment!.id!)}
                        onStart={() => props.onStart(w.appointment!.id!)}
                        onComplete={() =>
                            props.onComplete(
                                w.appointment!.id!,
                                w.appointment!.services[0].businessArea.id
                            )
                        }
                        onCheckout={() => props.onCheckout(w.appointment!.id!)}
                        onShowReport={() =>
                            props.onShowReport(
                                w.appointment!.id!,
                                w.appointment!.services[0].businessArea.id,
                                w.appointment!.customer.id
                            )
                        }
                        onCancel={() => props.onCancel(w.appointment!.id!)}
                        onNoShow={() => props.onNoShow(w.appointment!.id!)}
                        onShowInvoice={() =>
                            props.onShowInvoice(w.appointment!.id!)
                        }
                        onShowClient={props.onShowClient}
                        onShowPet={props.onShowPet}
                        onReschedule={() =>
                            props.onReschedule(w.appointment!.id!)
                        }
                        onBook={props.onBook}
                        onDecline={() =>
                            props.onDecline(
                                w.appointment!.id!,
                                w.appointment!.services[0].businessArea.id
                            )
                        }
                        onUndo={props.onUndoAppointment}
                        onShowAddReport={() =>
                            props.onShowAddReport(
                                w.appointment!.id!,
                                w.appointment!.services[0].businessArea.id,
                                w.appointment!.customer.id
                            )
                        }
                    />
                )
            )}
        </TableBodyMUI>
    );
};

export default TableBody;
