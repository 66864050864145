import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from 'components/UI';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

interface FooterComponentProps {
    withoutButton?: boolean;
    success?: boolean;
    isDetail?: boolean;
    title?: string;
    loading: boolean;
    total?: number | string;
    disabledButton?: boolean;
    hours?: number;
    minutes?: number;
    buttonText: string;
    deposit?: string;
    onBook: () => void;
    onRemove?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            margin: '16px 36px 13px 36px',
            [theme.breakpoints.up('md')]: {
                margin: '20px 40px 15px 40px'
            }
        },
        totalContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 16,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            }
        },
        depositContainer: {
            marginBottom: '14px !important',
            [theme.breakpoints.up('md')]: {
                marginBottom: '16 !important'
            }
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '16px'
        },
        normal: {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '16px'
        },
        button: {
            height: 50,
            [theme.breakpoints.down('sm')]: {
                borderRadius: 30
            },
            [theme.breakpoints.up('md')]: {
                height: 55
            },
            width: '100%'
        },
        buttonDisabled: {
            backgroundColor: '#D3D3D3 !important'
        },
        textContainer: {
            display: 'flex'
        },
        spacing: {
            marginLeft: 8,
            [theme.breakpoints.up('md')]: {
                marginLeft: 10
            }
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
        },
        iconRemoveContainer: {
            cursor: 'pointer',
            width: 55,
            height: 55,
            borderRadius: '50%',
            backgroundColor: '#F1F1F1',
            textAlign: 'center',
            alignContent: 'center'
        },
        remove: {
            width: '85% !important'
        },
        iconTrash: {
            fontSize: 26
        }
    })
);

export const FooterComponent: FunctionComponent<
    FooterComponentProps
> = props => {
    const classes = useStyles();

    const getTruncatedTotal = (total: string) => {
        const totalInteger = total.split('.');
        return totalInteger[totalInteger.length - 1] === '0' ||
            totalInteger[totalInteger.length - 1] === '00'
            ? totalInteger[0]
            : total;
    };

    const getPriceFormatted = (price: number) => {
        return `${price.toFixed(2)}`;
    };

    return (
        <Box className={classes.container}>
            {props.deposit && (
                <Box
                    className={clsx(
                        classes.totalContainer,
                        classes.depositContainer
                    )}
                >
                    <Typography className={classes.normal}>Deposit</Typography>
                    <Box className={classes.textContainer}>
                        <Typography
                            className={classes.normal}
                            id="booking_label_deposit_total"
                        >
                            {props.isDetail
                                ? `$${getPriceFormatted(
                                      parseFloat(props.deposit)
                                  )}`
                                : `$${getTruncatedTotal(props.deposit)}`}
                        </Typography>
                    </Box>
                </Box>
            )}
            {(props.total || !props.success || props.deposit) && (
                <Box className={classes.totalContainer}>
                    <Typography className={classes.title}>
                        {props.title}
                    </Typography>
                    <Box className={classes.textContainer}>
                        <Typography
                            className={classes.title}
                            id="booking_label_total"
                        >
                            {props.isDetail && props.total
                                ? `$${getPriceFormatted(
                                      typeof props.total === 'string'
                                          ? parseFloat(props.total)
                                          : props.total
                                  )}`
                                : `$${getTruncatedTotal(
                                      props.total?.toString() || '0'
                                  )}`}
                        </Typography>
                        {(props.hours || props.minutes) && !props.deposit && (
                            <Typography
                                className={clsx(classes.title, classes.spacing)}
                            >
                                {`(${props.hours ? props.hours + ' h ' : ''}${
                                    props.minutes ? props.minutes + ' min' : ''
                                })`}
                            </Typography>
                        )}
                    </Box>
                </Box>
            )}
            <Box className={classes.buttonContainer}>
                {props.onRemove && (
                    <Box
                        className={classes.iconRemoveContainer}
                        onClick={props.onRemove}
                    >
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            className={classes.iconTrash}
                        />
                    </Box>
                )}
                {!props.withoutButton && (
                    <Button
                        id={
                            props.success
                                ? 'booking_button_done'
                                : 'booking_button_book'
                        }
                        label={props.buttonText}
                        loading={props.loading}
                        color={props.success ? 'black' : 'green'}
                        size="large"
                        disabled={props.disabledButton}
                        variant="primary"
                        className={clsx(classes.button, {
                            [classes.buttonDisabled]: props.disabledButton,
                            [classes.remove]: props.onRemove
                        })}
                        onClick={props.onBook}
                    />
                )}
            </Box>
        </Box>
    );
};

export default FooterComponent;
