import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography, Divider, Badge } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt,
    faMapPin,
    faPhone
} from '@fortawesome/pro-light-svg-icons';
import { Button, Spinner } from 'components/UI';
import useStyles from './styles/PayByLinkStyles';
import AmountTable from './UI/AmountTable';
import CuddlesLogo from './UI/CuddlesLogo';
import { InvoiceLineDto } from '@spike/invoices-action';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    completeTransaction,
    confirmPayment,
    fetchPayByLink,
    lockInvoice,
    resetSaveTipError,
    saveTip
} from 'actions/payByLink/payByLinkActions';
import { RootState } from 'store';
import { showError } from '@spike/notifications-action';
import {
    AdyenPayment,
    CompletedInfo
} from 'components/Payments/Adyen/AdyenPayment';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

import InactivityModal from './UI/InactivityModal';
import LockedInvoice from './UI/LockedInvoice';
import { sessionUuid } from 'api/ApiClient';
import PaymentSuccess from './UI/PaymentSuccess';
import { AlertBox } from 'components/UI/Alerts';
import TipSection from './UI/TipSection';
import OrderDetails from './UI/OrderDetails';
import NotFound from 'components/UI/NotFound';
import { round } from 'lodash';

interface PayByLinkProps {
    uuid: string;
}
type TipType = string | 'none' | 'custom';

const base_url = `${process.env.REACT_APP_HOST_URL}`;

export const PayByLink: FunctionComponent<PayByLinkProps> = ({ uuid }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        paymentLink,
        loading: loadingInvoice,
        fetchError,
        saveTipError,
        lockInvoiceError
    } = useSelector((state: RootState) => state.payByLink);

    const [showInactivityModal, setShowInactivityModal] = useState(false);
    const [showLockError, setShowLockError] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [orderDetailsOpen, setOrderDetailsOpen] = useState(false);
    const [showCustomTipEdit, setShowCustomTipEdit] = useState<boolean>(true);
    const [selectedTip, setSelectedTip] = useState<TipType>('none');
    const [customTipType, setCustomTipType] = useState<'fixed' | 'percentage'>(
        'fixed'
    );
    const [customTipAmount, setCustomTipAmount] = useState<number>(0);
    const marketplace = paymentLink?.marketplace;
    const tips = marketplace?.tip_rates ?? [];

    const blockTimeoutInSeconds = Number(
        process.env.REACT_APP_BLOCK_TIMEOUT_SECONDS || 60
    );

    /*Dropin */
    const [showDropin, setShowDropin] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showErrorPayment, setShowErrorPayment] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [completing, setCompleting] = useState(false);
    const errorPaymentMessage = (
        <span>
            <strong>Payment cannot be processed.</strong> Please try a different
            card or contact {marketplace?.business_name}. If you have any
            questions, contact <strong>{marketplace?.business_name}</strong> at{' '}
            <strong>{marketplace?.phone}</strong>.
        </span>
    );

    const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);

    const dropinUrl =
        base_url +
        '/api/v1/create_dropin?marketplace_id=' +
        paymentLink?.marketplace.id +
        '&invoice_id=' +
        paymentLink?.invoice.id;

    useEffect(() => {
        dispatch(fetchPayByLink(uuid));
    }, [uuid]);

    useEffect(() => {
        if (paymentLink) {
            lockInvoiceDispatch();
            setLoadingPage(false);
        }
    }, [paymentLink]);

    useEffect(() => {
        window.addEventListener('beforeunload', unlock);
        window.addEventListener('pagehide', unlock);

        return () => {
            window.removeEventListener('beforeunload', unlock);
            window.removeEventListener('pagehide', unlock);
        };
    }, [paymentLink]);

    useEffect(() => {
        const events = ['keydown'];

        events.forEach(event => {
            window.addEventListener(event, resetInactivityTimer);
        });

        resetInactivityTimer(); // Initialize the timer when the component mounts

        return () => {
            if (inactivityTimerRef.current) {
                clearTimeout(inactivityTimerRef.current);
            }
            events.forEach(event => {
                window.removeEventListener(event, resetInactivityTimer);
            });
        };
    }, [paymentLink]);

    useEffect(() => {
        if (lockInvoiceError) {
            setShowLockError(true);
        }
    }, [lockInvoiceError]);

    useEffect(() => {
        if (fetchError || saveTipError) {
            setLoadingPage(false);
            if (saveTipError) {
                dispatch(showError(saveTipError));
                dispatch(resetSaveTipError());
                lockInvoiceDispatch();
            }
        }
    }, [fetchError, saveTipError]);

    const resetInactivityTimer = () => {
        if (inactivityTimerRef.current) {
            clearTimeout(inactivityTimerRef.current);
        }
        inactivityTimerRef.current = setTimeout(() => {
            setShowInactivityModal(true);
            setShowDropin(false);
            setShowErrorPayment(false);
            setShowSuccess(false);
            unlock();
        }, blockTimeoutInSeconds * 1500);
    };

    const lockInvoiceDispatch = () => {
        if (paymentLink && paymentLink.marketplace.id) {
            dispatch(
                lockInvoice(
                    paymentLink.invoice.booking_id,
                    paymentLink.marketplace.id,
                    sessionUuid,
                    paymentLink.token
                )
            );
        }
    };

    const unlock = () => {
        fetch(
            `${base_url}/api/v1/booking/unlock/${paymentLink?.invoice.booking_id}?marketplace_id=${paymentLink?.marketplace.id}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Session-Uuid': sessionUuid,
                    'Authorization': `Bearer ${paymentLink?.token}`
                },
                body: '',
                keepalive: true
            }
        );
    };

    const paymentErrorHandler = (message: string) => {
        if (message.length > 1) {
            setShowDropin(false);
            setShowErrorPayment(true);
            setCompleting(false);
        }
    };
    const completeHandler = () => {
        setShowDropin(true);
        lockInvoiceDispatch();
        resetInactivityTimer();
        setShowErrorPayment(false);
    };

    const paymentCompleteHandler = async (completedInfo: CompletedInfo) => {
        if (completedInfo.success) {
            console.log(successMessage);
            if (paymentLink?.invoice.id && paymentLink?.marketplace.id) {
                await dispatch(
                    confirmPayment(
                        paymentLink.invoice.id,
                        paymentLink.marketplace.id,
                        sessionUuid,
                        paymentLink.token,
                        completedInfo.sessionId
                    )
                );
                // After confirmPayment is complete, dispatch completeTransaction
                dispatch(
                    completeTransaction(
                        paymentLink.invoice.id,
                        paymentLink.marketplace.id,
                        sessionUuid,
                        paymentLink.token,
                        paymentLink.invoice.customer_email
                    )
                );
                setSuccessMessage(completedInfo.message);
                setShowSuccess(true);
                setShowDropin(false);
            }
        } else {
            setErrorMessage(completedInfo.message);
            setShowErrorPayment(true);
            setShowDropin(false);
            console.log(errorMessage);
        }
    };

    const saveTipAmount = async (
        amount: number | null,
        percentage: string | null
    ) => {
        if (
            paymentLink?.invoice.id &&
            marketplace &&
            paymentLink.marketplace.id
        ) {
            await dispatch(
                saveTip(
                    paymentLink.invoice.id,
                    paymentLink.marketplace.id,
                    uuid,
                    amount !== null ? amount.toString() : null,
                    percentage,
                    paymentLink.token
                )
            );
            lockInvoiceDispatch();
        }
    };

    const handleTipSelect = (tip: TipType) => {
        if (showDropin) return;
        setSelectedTip(tip);
        if (tip === 'none') {
            setCustomTipAmount(0);
            setShowCustomTipEdit(false);
            saveTipAmount(0, null);
        } else if (tip === 'custom') {
            setShowCustomTipEdit(true);
        } else {
            setCustomTipType('fixed');
            setShowCustomTipEdit(false);
            const selectedTipAmount = calculateTipAmount(
                Number(tip.replace('%', '')),
                Number(paymentLink?.invoice.subtotal)
            );
            saveTipAmount(null, tip.replace('%', ''));
        }
    };

    const handleCustomTipConfirm = () => {
        if (customTipAmount > 0) {
            if (customTipType === 'percentage') {
                saveTipAmount(null, customTipAmount.toString());
            } else {
                saveTipAmount(customTipAmount, null);
            }
            setShowCustomTipEdit(false);
        } else {
            handleTipSelect('none');
        }
    };

    const handleCustomTipTypeSelect = (type: 'fixed' | 'percentage') => {
        setCustomTipType(type);
    };

    const handleCustomTipAmountChange = (value: number) => {
        setCustomTipAmount(value);
    };

    const handleCustomTipCancel = () => {
        if (customTipAmount > 0) {
            setShowCustomTipEdit(false);
        } else {
            handleTipSelect('none');
        }
    };

    /*
    const calculateTipAmount = (
        percentage: number,
        subtotal: number
    ): string => {
        const tipAmount = (percentage / 100) * subtotal;
        return tipAmount.toFixed(2);
    };
    */

    const calculateTipAmount = (
        percentage: number,
        subtotal: number
    ): string => {
        return round((Number(subtotal) * percentage) / 100, 2)
            .toFixed(2)
            .toString();
    };

    const handleEditCustomTip = () => {
        setShowCustomTipEdit(true);
    };

    const toggleOrderDetails = () => {
        setOrderDetailsOpen(!orderDetailsOpen);
    };

    useEffect(() => {
        if (paymentLink) {
            const invoiceTipAmount = Number(paymentLink.invoice.tip_amount);
            const invoiceTipPercentage = paymentLink.invoice.tip_percentage;
            let initialTip: TipType = 'none';

            // Check if the invoice tip amount matches any of the default tips
            tips.forEach(tip => {
                const calculatedTipAmount = calculateTipAmount(
                    Number(tip.value),
                    Number(paymentLink.invoice.subtotal)
                );
                if (Number(calculatedTipAmount) === invoiceTipAmount) {
                    initialTip = `${tip.value}%`;
                }
            });

            if (initialTip === 'none') {
                if (invoiceTipPercentage !== null) {
                    initialTip = 'custom';
                    setCustomTipAmount(Number(invoiceTipPercentage));
                    setCustomTipType('percentage');
                    setShowCustomTipEdit(false);
                } else if (invoiceTipAmount > 0) {
                    initialTip = 'custom';
                    setCustomTipAmount(invoiceTipAmount);
                    setShowCustomTipEdit(false); // Show the amount container instead of the edit container
                }
            }

            setSelectedTip(initialTip);
        }
    }, [paymentLink, tips]);

    const calculateTotalDiscount = (
        invoiceLines: Array<InvoiceLineDto> | undefined
    ): string => {
        if (!invoiceLines) return '0.00';
        const totalDiscount = invoiceLines.reduce((total, line) => {
            if (line.discount_amount) {
                return total + parseFloat(line.discount_amount);
            }
            return total;
        }, 0);
        return totalDiscount.toFixed(2);
    };

    if (loadingPage) {
        return <Spinner className={classes.spinnerStyle} />;
    }

    if (showLockError) {
        return <LockedInvoice />;
    }

    let content: JSX.Element | null = null;

    if (paymentLink) {
        const taxDetails = paymentLink.invoice.tax_detail || {};

        const totalAmountRows = [
            {
                label: 'Subtotal',
                value: `$${Number(paymentLink.invoice.subtotal).toFixed(2)}`
            },
            // ...(calculateTotalDiscount(paymentLink.invoice.invoice_lines) !==
            // '$0.00'
            //     ? [
            //           {
            //               label: 'Discount',
            //               value: `-$${calculateTotalDiscount(
            //                   paymentLink.invoice.invoice_lines
            //               )}`
            //           }
            //       ]
            //     : [{ label: 'Discount', value: '$0.00' }]),
            ...Object.entries(taxDetails).map(([key, value]) => ({
                label: key,
                value: `$${Number(value).toFixed(2)}`
            })),
            {
                label: 'Tip',
                value:
                    `$${Number(paymentLink.invoice.tip_amount).toFixed(2)}` ||
                    '$0.00'
            }
        ];

        const orderDetailsRows = paymentLink.invoice.invoice_lines
            ? paymentLink.invoice.invoice_lines
                  .filter(line => line.description) // Filter out lines with no description
                  .map(line => ({
                      label: line.staff_name
                          ? `${line.description} by <strong>${line.staff_name}</strong>`
                          : line.description!,
                      quantity: line.quantity,
                      discountedValue: `$${line.amount}`,
                      value: `$${line.total}`
                  }))
            : [];

        const businessName = paymentLink.invoice.business_name || '';
        const businessLogoUrl = paymentLink.invoice.logo_url || '';

        content = (
            <Box className={classes.body}>
                <Box className={classes.storeInfo}>
                    <Box className={classes.logo}>
                        {businessLogoUrl ? (
                            <img src={businessLogoUrl} alt="Store Logo" />
                        ) : (
                            <Typography className={classes.storeName}>
                                {businessName}
                            </Typography>
                        )}
                    </Box>
                    <Box className={classes.storeDetails}>
                        <Typography className={classes.storeName}>
                            {businessName}
                        </Typography>
                        <Typography className={classes.storeContact}>
                            <FontAwesomeIcon
                                className={classes.storeInfoIcon}
                                icon={faPhone}
                                style={{ marginRight: 8 }}
                            />
                            {paymentLink.invoice.customer_phone || 'N/A'}
                        </Typography>
                        <Typography className={classes.storeAddress}>
                            <FontAwesomeIcon
                                className={classes.storeInfoIcon}
                                icon={faMapPin}
                                style={{ marginRight: 8 }}
                            />
                            {paymentLink.invoice.address || 'No Address'}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.boxDefault}>
                    <Box className={classes.bookingDetails}>
                        <Badge
                            classes={{ badge: classes.customBadge }}
                            badgeContent={
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            }
                        />
                        <Box className={classes.bookingDetailsInfo}>
                            <Typography
                                variant="h2"
                                className={classes.bookingTitle}
                            >
                                Booking Details
                            </Typography>
                            <Typography variant="body2">
                                {moment(paymentLink.invoice.appt_date).format(
                                    'dddd, DD MMM YYYY - hh:mmA'
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {!showDropin && (
                    <TipSection
                        tips={tips}
                        selectedTip={selectedTip}
                        handleTipSelect={handleTipSelect}
                        showCustomTipEdit={showCustomTipEdit}
                        customTipType={customTipType}
                        handleCustomTipTypeSelect={handleCustomTipTypeSelect}
                        customTipAmount={customTipAmount}
                        handleCustomTipAmountChange={
                            handleCustomTipAmountChange
                        }
                        handleCustomTipConfirm={handleCustomTipConfirm}
                        handleCustomTipCancel={handleCustomTipCancel}
                        calculateTipAmount={calculateTipAmount}
                        subtotal={Number(paymentLink.invoice.subtotal)}
                        classes={classes}
                        handleEditCustomTip={handleEditCustomTip}
                    />
                )}

                <Box className={classes.invoiceContent}>
                    {loadingInvoice ? (
                        <Box className={classes.spinnerContent}>
                            <Spinner />
                        </Box>
                    ) : (
                        <>
                            <AmountTable
                                title="Total Amount"
                                rows={totalAmountRows}
                                total={{
                                    label: 'Total',
                                    value: `$${Number(
                                        paymentLink.invoice.grand_total
                                    ).toFixed(2)}`
                                }}
                                dueTotal={{
                                    label: 'Due Total',
                                    value: `$${Number(
                                        paymentLink.invoice.due_total
                                    ).toFixed(2)}`
                                }}
                                deposit={
                                    Number(paymentLink.invoice.deposits_total) >
                                    0
                                        ? {
                                              label: 'Deposit',
                                              value: `$${Number(
                                                  paymentLink.invoice
                                                      .deposits_total
                                              ).toFixed(2)}`
                                          }
                                        : undefined
                                }
                            />

                            <Grid item xs={12} className={classes.divider}>
                                <Divider />
                            </Grid>

                            {!showDropin && (
                                <OrderDetails
                                    orderDetailsRows={orderDetailsRows}
                                    orderDetailsOpen={orderDetailsOpen}
                                    toggleOrderDetails={toggleOrderDetails}
                                    classes={classes}
                                />
                            )}

                            {(showDropin || showErrorPayment) && (
                                <Box className={classes.paymentBlock}>
                                    <Typography
                                        variant="h2"
                                        className={classes.bookingTitle}
                                    >
                                        Payment
                                    </Typography>
                                    <Typography
                                        className={classes.subtitle}
                                        variant="body2"
                                    >
                                        Safe and encrypted payments
                                    </Typography>
                                </Box>
                            )}

                            {showErrorPayment && (
                                <AlertBox
                                    type="error"
                                    className={classes.alertBox}
                                    text={errorPaymentMessage}
                                    actionable={false}
                                />
                            )}

                            {!showDropin && (
                                <Box className={classes.payButtonBlock}>
                                    <Button
                                        color="green"
                                        size="large"
                                        label={'Pay'}
                                        id="pay_by_link_confirm_pay_button"
                                        className={classes.button}
                                        onClick={completeHandler}
                                        loading={completing}
                                    />
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        );
    }

    return (
        <Box className={classes.root}>
            {!fetchError && (
                <Box className={classes.titleContainer}>
                    {(showDropin || showErrorPayment) && (
                        <Box
                            className={classes.backButton}
                            onClick={() => {
                                setShowDropin(false);
                                setShowErrorPayment(false);
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                            <Typography className={classes.backButtonText}>
                                Back
                            </Typography>
                        </Box>
                    )}
                    <Typography className={classes.title}>
                        Pay By Link
                    </Typography>
                </Box>
            )}
            <Box className={classes.wrapper}>
                {fetchError ? (
                    <NotFound
                        customContainerClass={classes.notFoundContainer}
                        title="Oops! Payment Link Not Found"
                        subtitle="Oops! We couldn't find the payment link you're looking for."
                    />
                ) : showSuccess ? (
                    paymentLink && (
                        <PaymentSuccess
                            totalPaid={paymentLink?.invoice.due_total}
                            paymentDate={moment().format('dddd, DD MMM YYYY')}
                        />
                    )
                ) : showInactivityModal ? (
                    <InactivityModal />
                ) : (
                    content
                )}
            </Box>

            {showDropin && paymentLink && uuid && (
                <Box className={classes.wrapper}>
                    <Box className={classes.body}>
                        <AdyenPayment
                            className={classes.dropinContainer}
                            hideRemovePaymentMethodButton={true}
                            hideStoredPaymentMethods={true}
                            onComplete={paymentCompleteHandler}
                            onError={paymentErrorHandler}
                            paymentLinkDropInUrl={dropinUrl}
                            authToken={paymentLink.token}
                        />
                    </Box>
                </Box>
            )}
            {!fetchError && (
                <Box className={classes.footer}>
                    <Typography className={classes.footerText}>
                        Powered by
                    </Typography>
                    <CuddlesLogo className={classes.cuddlesLogo} />
                </Box>
            )}
        </Box>
    );
};

export default PayByLink;
